const polygonLayer = function(source = false, id = "polygons"){
  if(!source)
    throw new Error("No source provided for polygon layer!");

  return {
    "id": id,
    "type": "fill",
    "source": source,
    "paint": {
      "fill-color": ["get", "fill_color"],
      "fill-opacity": ["get", "fill_opacity"],
    },
    "filter": ["==", "$type", "Polygon"],
  };
};

const outlineLayer = function(source = false, id = "outline"){
  if(!source)
    throw new Error("No source provided for outline layer!");

  return {
    "id": id,
    "type": "line",
    "source": source,
    "paint": {
      "line-color": ["get", "line_color"],
      "line-width": ["get", "line_width"],
    },
  };
};

const pointLayer = function(source = false, id = "points"){
  if(!source)
    throw new Error("No source provided for point layer!");

  return {
    "id": id,
    "type": "symbol",
    "source": source,
    "paint": {
      "text-color": ["get", "text_color"],
      "text-halo-color": ["get", "text_halo_color"],
      "text-halo-width": ["get", "text_halo_width"],
    },
    "layout": {
      "icon-image": ["get", "icon"],
      "icon-offset": [0, -15],
      "icon-size": 0.9,
      "text-field": ["get", "label_title"], //* get the title name from the source's "label_title" property
      "text-size": ["get", "text_size"],
      "text-justify": "center",
      "text-optional": true,
      "text-font": [
        "Open Sans Semibold",
        "Arial Unicode MS Bold",
      ],
      "text-offset": [0, 0],
      "text-anchor": "top",
      "text-allow-overlap": false,
      "icon-allow-overlap": true,
    },
    "filter": ["==", "$type", "Point"],
  };
};

const polygonLabelLayer = function(source = false, id = "polygon_labels"){
  if(!source)
    throw new Error("No source provided for polygon label layer!");

  return {
    "id": id,
    "type": "symbol",
    "source": source,
    "paint": {
      "text-color": ["get", "text_color"],
      "text-halo-color": ["get", "text_halo_color"],
      "text-halo-width": ["get", "text_halo_width"],
    },
    "layout": {
      "text-field": ["get", "label_title"], //* get the title name from the source's "label_title" property
      "text-size": ["get", "text_size"],
      "text-justify": "center",
      "text-optional": true,
      "text-font": [
        "Open Sans Semibold",
        "Arial Unicode MS Bold",
      ],
      "text-offset": [0, 0],
      "text-anchor": "top",
      "text-allow-overlap": false,
      "icon-allow-overlap": true,
    },
    "filter": ["==", "$type", "Polygon"],
  };
};

const pathLabelLayer = function(source = false, id = "path_labels"){
  if(!source)
    throw new Error("No source provided for path label layer!");

  return {
    "id": id,
    "type": "symbol",
    "source": source,
    "paint": {
      "text-color": ["get", "text_color"],
      "text-halo-color": ["get", "text_halo_color"],
      "text-halo-width": ["get", "text_halo_width"],
    },
    "layout": {
      "text-field": ["get", "label_title"], //* get the title name from the source's "label_title" property
      "text-size": ["get", "text_size"],
      "text-justify": "center",
      "text-optional": true,
      "text-font": [
        "Open Sans Semibold",
        "Arial Unicode MS Bold",
      ],
      "text-offset": [0, 0],
      "text-anchor": "top",
      "text-allow-overlap": false,
      "icon-allow-overlap": true,
    },
    "filter": ["==", "$type", "LineString"],
  };
};

const useLayerStyles = controller => {
  Object.assign(controller, {
    polygonLayer,
    outlineLayer,
    pointLayer,
    polygonLabelLayer,
    pathLabelLayer,
  });
};

export { polygonLayer, outlineLayer, pointLayer, polygonLabelLayer, pathLabelLayer, useLayerStyles };

export default useLayerStyles;
